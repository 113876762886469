import Vue from 'vue'
import VueWorker from 'vue-worker'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueSimpleAlert from "vue-simple-alert";
//import SWorker from 'simple-web-worker'

Vue.config.productionTip = false
Vue.use(VueWorker)
Vue.use(VueSimpleAlert);



new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')


Vue.component('editable-cell', {
    props: {
        value: String,
    },
    data() {
        return {
            isEditing: false,
            editedValue: '',
        };
    },
    watch: {
        value(newVal) {
            this.editedValue = newVal;
        },
    },
    methods: {
        toggleEditing() {
            this.isEditing = !this.isEditing;
            if (this.isEditing) {
                this.$nextTick(() => {
                    this.$refs.editorInput.focus();
                });
            }
        },
        saveValue() {
            this.$emit('save', this.editedValue);
            this.isEditing = false;
        },
    },
    template: `
        <div>
            <div v-if="!isEditing" @click="toggleEditing">{{ value }}</div>
            <v-text-field
                v-else
                v-model="editedValue"
                ref="editorInput"
                @blur="saveValue"
            ></v-text-field>
        </div>
    `,
});

// let w;

// function startWorker() {
//     if (typeof(w) == "undefined") {
//       w = new Worker("@/script/worker.js");
//       console.log( "startWorker" );
//       //vm.App.tickCounter = 55;
//     }
// }
// //     //w.onmessage = function(event) {
//     w.onmessage = function(event) {
//       //document.getElementById("result").innerHTML = event.data;
//       vm.app.tickCounter = event.data;
//       console.log( "onmessage" );
//     };
//   }
  
//   function stopWorker() {
//     w.terminate();
//     w = undefined;
//   }

//startWorker(); 

// function func1_body()
// {

//     setTimeout("func1_body()",500);
//     return 'Hello, world!';
// }

// const actions = [
//     { message: 'func1', func: func1_body }
 
    
    

//   ]
  
// let worker = SWorker.create(actions);

// worker.postMessage('func1')
//   .then(console.log) // logs 'Worker 1: Working on func1'
//   .catch(console.error) // logs any possible error


// console.log( "created: ");
            
// let ar_data = { tick:0, items: [] };
// //let ar_data = { tick:0 };
// let ii;
// for( ii=0; ii<8; ii++ )
// {
//     let item = {
//             t_num:      ii+1,
//             t_in:       0, 
//             t_out:      0,
//             t_level:    0,
//             t_pump:     0
//     };
//     ar_data.items[ar_data.items.length] = item;
// }

// newFunction() 

// function newFunction() {
//     for (let ii = 0; ii < 8; ii++) {

//         console.log(ii + "tankData.items.t_num " + ar_data.items[ii].t_num)
//     }
// }
