<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">

        <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src = "logo-2-227x110.png"

          transition="scale-transition"
          width="110"
        />
 
      </div>

      <v-spacer></v-spacer>

      <v-label v-if="is_login==1">
            <span class="mr-2">{{ user_name }}</span>
      </v-label>

      <v-btn v-if="is_login==1"
        target="_blank"
        text
        v-on:click="logout" 
      >
        <span class="mr-2">logout</span>
      </v-btn> 
    </v-app-bar>

    <v-main v-if="is_login==0" >
      <LoginPage @login-approve="login_event" /> 
    </v-main>

    <v-main v-if="is_login==1">
      <ContainerGroup  :container_list=container_list  :user_token=user_token :poll_enable=is_login />
    </v-main>
  

  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// import TankContent from "@/components/TankContent";
//import ContainerContent from "@/components/ContainerContent";
import ContainerGroup from "@/components/ContainerGroup";
import LoginPage from "@/components/LoginPage";
//import SimpleGrid from "@/components/SimpleGrid";
//import VueWorker from 'vue-worker'
//import { workerData } from 'worker_threads';

//const worker = new Worker( '/script/worker.js' );



export default {
  name: 'App',

  components: {
    LoginPage,
    ContainerGroup
    //SimpleGrid
  },


  data: () => ({
    is_login : 0,
    user_name : "",
    user_token : "",
    container_list : "",
    poll_enable : ""

    // searchQuery: '',
    // gridColumns: ['name', 'power'],
    // gridData: [
    //   { name: 'Chuck Norris', power: Infinity },
    //   { name: 'Bruce Lee', power: 9000 },
    //   { name: 'Jackie Chan', power: 7000 },
    //   { name: 'Jet Li', power: 8000 }
    // ]

  }),

  computed: {   

    urlBase : function () { return process.env.VUE_APP_BASE_URI },    


   },

  methods: {
    logout: function () {
      this.is_login=0 
    },

    getContainerList : function () {
        let req = new XMLHttpRequest();
        req.onload = function() {
            if (req.status == 200) {
                    this.reqCntOk++;
                    let json_o = JSON.parse( req.responseText );
                    this.answer = json_o;

                    
                    let is_approved  = json_o.is_approved;

                    console.log( json_o );
                    console.log( "containers#1:" + json_o.containers );

                    this.is_login = is_approved;
                    this.container_list = json_o.containers;
                    //ContainerGroup.container_list = json_o.containers;
                    //this.ContainerGroup.methods["setContainer"]( json_o.containers );

            } else {
                    //myCallback("Error: " + req.status);
                    //this.reqCntError++;
                    this.answer = 'no answer';
            }
        }.bind(this)

        
        let json = JSON.stringify({
            token : this.user_token,
            cmd   : "get_container_list"
        });

        console.log( "send json" + json );

        var url = "req=" + json.toString();
        //var url = json.toString();


        console.log( url );

        req.withCredentials = true;
        req.open("POST", this.urlBase+'/f/data', true)

        req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        //req.setRequestHeader("Content-type", "multipart/form-data");
        //req.setRequestHeader("Content-type", "application/json; charset=utf-8" );
        req.send(url);
        //req.send( json );

    },
    
    login_event: function ( user, token ) {
      this.is_login=1;
      this.user_name = user;
      this.user_token = token;
      console.log('login_event: ', this.user_name, '  ', this.user_token );
      this.getContainerList();
    }    
  }  
};
</script>
