

  <template>

    
    <v-container class="grey lighten-5">
        <v-row >
            <v-col>
         
                <p> {{urlBase}}  tickCounter: {{tickCounter}} {{reqCntOk}}  {{reqCntError}}</p>
                <!-- <p> {{container_list}} {{user_token}} {{poll_enable}} countCountainer={{ countCountainer }}</p>
                <p> {{container_list2}} </p>
                <p> {{ currentRow }}</p> -->
                <!-- <p>{{reqResponseText}}</p> -->
                
            </v-col>
        </v-row>
      <v-row >
        <v-col style="max-width:380px">  <h3>Containers</h3> 
            <!-- <v-row no-gutters 
              v-for="n in countCountainer"
              :key="n"
            >
            <v-col >
                List item  {{container_list2[n-1]}} 
            </v-col>
            </v-row> -->
            <v-row  >
                <v-col>

                <v-data-table
                    :headers="headers_list"
                    :items="container_list2"
                    class="elevation-1" 
                    hide-default-footer
                    @click:row = "onClickRow"
                    
                >

                <!-- <template v-slot:item="{ item }">
                    <tr :class="item.n=={currentRow}?'cyan':''" @click="onClickRow(item)">
                        <td>{{item.n}}</td>
                        <td>{{item.type}}</td>
                        <td>{{item.sn}}</td>
                        <td>{{item.state}}</td>
                        <td>{{item.hb}}</td>
                    </tr>
                </template>                 -->
                </v-data-table>
                </v-col> 
            </v-row>
        </v-col>
        <v-col no-gutters><h3 align="left">Container {{ currentRow }} - {{ container_list2[currentRow-1].type }} {{ container_list2[currentRow-1].sn }} </h3>
        <!-- <v-row>
            <v-col>
                <br>
                <h5>TANKS LEVEL</h5>
                <v-data-table
                    :headers="headers_tank_level"
                    :items="data_tank_level"
                    hide-default-footer
                    class="elevation-1" 
                >
                </v-data-table>
            </v-col>   
        </v-row>            
        <v-row>
            <v-col>
                <br>
                <h5>TANKS TEMPERATURE</h5>
                <v-data-table
                    :headers="headers_tank_tm"
                    :items="data_tank_tm"
                    hide-default-footer
                    class="elevation-1" 
                >
                </v-data-table>
            </v-col>   
        </v-row>             -->


        <v-row>
            <v-col>
                <br>
                <h5>TANKS</h5>
                <v-data-table
                    :headers="headers_tank_tm"
                    :items="data_tank_tm"
                    hide-default-footer
                    class="elevation-1" 
                >
                </v-data-table>
            </v-col>   
        </v-row>  

        <v-row>
            <v-col>
                <br>
                <h5>FANS SPEED</h5>
                <v-data-table
                    :headers="headers_fan_left"
                    :items="data_fan_left"
                    hide-default-footer
                    class="elevation-1" 
                    
                >
                </v-data-table>
            </v-col>   
        </v-row>  

        <!-- <v-row>
            <v-col>
                <br>
                <h5>RIGHT FANS SPEED</h5>
                <v-data-table
                    :headers="headers_fan_right"
                    :items="data_fan_right"
                    hide-default-footer
                    class="elevation-1" 
                    
                >
                </v-data-table>
            </v-col>   
        </v-row>   -->

        <v-row>
            <v-col>
                <br>
                <h5>PUMP</h5>
                <v-data-table
                    :headers="headers_pump"
                    :items="data_pump"
                    hide-default-footer
                    class="elevation-1" 
                    
                >
                </v-data-table>
            </v-col>   
            <v-col>
                <br>
                <h5>Parameters</h5>
                <!-- <v-data-table
                    :headers="headers_param"
                    :items="data_param"
                    hide-default-footer
                    class="elevation-1" 
                    
                >
                </v-data-table> -->

                <form>
                <div class="form-group" align-left>
                <label for="FAN_SPEED">FAN_SPEED:</label>
                <label class="input-field">{{curr_fan_speed}}</label>
                <input type="number" id="fan_speed" v-model.number="new_fan_speed" class="input-field">
                </div>
                <div class="form-group" align-left>
                <label >PUMP #1   :</label>
                <label class="input-field">{{curr_pump_1_freq}}</label>
                <input type="number" id="pump_1_freq" v-model.number="new_pump_1_freq" class="input-field">
                </div>
                <div class="form-group" align-left>
                <label >PUMP #2   :</label>
                <label class="input-field">{{curr_pump_2_freq}}</label>
                <input type="number" id="pump_1_freq" v-model.number="new_pump_2_freq" class="input-field">
                </div>
                <div class="form-group" align-left>
                <label >MODE   :</label>
                <label class="input-field">{{curr_auto_mode}}</label>
                <input type="number" id="new_auto_mode" v-model.number="new_auto_mode" class="input-field">
                <!-- <select v-model="new_auto_mode" class="input-field">
                    <option>Manual</option>
                    <option>Auto</option>
                </select> -->
                
                </div>
                <br>
            </form>                
            </v-col>   


            <v-col>
                <br>
                <form>
                <div class="form-group" align-left>
                <label >PIN:   </label>
                <input type="number" id="pin" v-model.number="new_pin" class="input-field" >
                <label >    </label>
                </div>
                <br>
                <button type="submit" @click.prevent="onSetNewParam" class="login-button">Apply</button>
                <br>
                <label class="input-field">{{message_send}}</label>
                </form>                
            </v-col>   

        </v-row>  

        <!-- <v-row>
            <v-col>
                <br>
                <h5>RADIATORS TEMPERATURE</h5>
                <v-data-table
                    :headers="headers_radiator_tm"
                    :items="data_radiator_tm"
                    hide-default-footer
                    class="elevation-1" 
                    
                >
                </v-data-table>
            </v-col>   
        </v-row>   -->

        </v-col>
        <v-col>

            <v-row>
                   <h5>AUTO_MODE_PARAM</h5>
                   <br>
                    <v-data-table
                        :headers="headers_a_param"
                        :items="data_a_param"
                        hide-default-footer
                        class="elevation-1" 
                        
                    >

                    <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.name">
                            <td>
                                <v-edit-dialog :return-value.sync="item.name" > 
                                    <div>{{ item.name }}</div>
                                </v-edit-dialog>
                            </td>

                            <td>
                                <v-edit-dialog :return-value.sync="item.value" @save="editItem(item)">
                                    <div>{{ item.value }}</div>
                                </v-edit-dialog>
                            </td>

                            <td>
                                <v-edit-dialog :return-value.sync="item.new_value" @save="editItem(item)">
                                    <div>{{ item.new_value }}</div>

                                    <template v-slot:input>
                                        <v-text-field
                                        v-model.number="item.new_value"
                                        :rules="[max25chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        
                                        ></v-text-field>
                                    </template>                                    
                                </v-edit-dialog>
                                
                            </td>

                        </tr>

                    </tbody>
                </template>                    

                  </v-data-table>                
            </v-row>
        </v-col>

        
      </v-row>
    </v-container>

  </template>

<style>
  tr.v-data-table__selected {
    background: #7d92f5 !important;
  }
</style>

  <script>
   //import TankContent from "@/components/TankContent";
  
   //import SimpleGrid from "@/components/SimpleGrid";

  export default {
    name: "ContainerGroup",
    props: ["container_list", "poll_enable", "user_token" ],
    components: {
        //SimpleGrid
    },
    data: () => ({

        searchQuery: '',
        //gridColumns: ['n', 'type', 'sn', 'state', 'hb'],
        selectedRows: [],
        headers_list:[
            { text: 'n',    value: 'n', align: 'start', sortable: false },
            { text: 'type', value: 'type' },
            { text: 's/n',  value: 'sn' },
            { text: 'state', value: 'state' },
            { text: 'hb',   value: 'hb' },
        ],

        data_list: [],
        // headers_container:[
        //     { text: 'n',      value: 'n', align: 'start', sortable: false },
        //     { text: 'name',   value: 'name' },
        //     { text: 'value',  value: 'value' },
        //     { text: 'unit',   value: 'unit' },
            
        // ],

        // data_container : [
        //     { n:0, name : 'level', value : 'low', unit : ''}
        // ],


        headers_tank_level:[
            //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
            { text: 'tanks_#1',   value: 't1_level', sortable: false  },
            { text: 'tanks_#2',   value: 't2_level', sortable: false  },
            { text: 'tanks_#3',   value: 't3_level', sortable: false  },
            { text: 'tanks_#4',   value: 't4_level', sortable: false  },
            { text: 'tanks_#5',   value: 't5_level', sortable: false  },
            { text: 'tanks_#6',   value: 't6_level', sortable: false  },
            { text: 'tanks_#7',   value: 't7_level', sortable: false  },
            { text: 'tanks_#8',   value: 't8_level', sortable: false  },
            
        ],

        data_tank_level : [
            { t1_level : 'CRITICAL', t2_level : 'CRITICAL', t3_level : 'CRITICAL', t4_level : 'CRITICAL', 
              t5_level : 'CRITICAL', t6_level : 'CRITICAL', t7_level : 'CRITICAL', t8_level : 'CRITICAL' }
        ],

        headers_tank_tm:[
            //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
            { text: 'param',     value: 'param', sortable: false  },
            { text: 'tank_#1',   value: 't1_t', sortable: false  },
            { text: 'tank_#2',   value: 't2_t', sortable: false  },
            { text: 'tank_#3',   value: 't3_t', sortable: false  },
            { text: 'tank_#4',   value: 't4_t', sortable: false  },
            { text: 'tank_#5',   value: 't5_t', sortable: false  },
            { text: 'tank_#6',   value: 't6_t', sortable: false  },
            { text: 'tank_#7',   value: 't7_t', sortable: false  },
            { text: 'tank_#8',   value: 't8_t', sortable: false  },
            
        ],

        data_tank_tm : [
            { param : 'T_INPUT',  t1_t : 0,  t2_t : 0,  t3_t : 0,  t4_t : 0, t5_t : 0,  t6_t : 0,  t7_t : 0,  t8_t : 0 },
            { param : 'T_OUTPUT', t1_t : 0,  t2_t : 0,  t3_t : 0,  t4_t : 0, t5_t : 0,  t6_t : 0,  t7_t : 0,  t8_t : 0 },
            { param : 'LEVEL',    t1_t : 0,  t2_t : 0,  t3_t : 0,  t4_t : 0, t5_t : 0,  t6_t : 0,  t7_t : 0,  t8_t : 0 },
        ],

        headers_fan_left:[
            //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
            { text: 'param',   value: 'param', sortable: false  },
            { text: 'fan_#1',   value: 'f1_s', sortable: false  },
            { text: 'fan_#2',   value: 'f2_s', sortable: false  },
            { text: 'fan_#3',   value: 'f3_s', sortable: false  },
            { text: 'fan_#4',   value: 'f4_s', sortable: false  },
            { text: 'fan_#5',   value: 'f5_s', sortable: false  },
            { text: 'fan_#6',   value: 'f6_s', sortable: false  },
            { text: 'fan_#7',   value: 'f7_s', sortable: false  },
            
        ],

        data_fan_left : [
            { param : 'LEFT',   f1_s : 0,  f2_s : 0,  f3_s : 0,  f4_s : 0,  f5_s : 0,  f6_s : 0,  f7_s : 0 },
            { param : 'RIGHT',  f1_s : 0,  f2_s : 0,  f3_s : 0,  f4_s : 0,  f5_s : 0,  f6_s : 0,  f7_s : 0 }
        ],

        headers_fan_right:[
            //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
            { text: 'param',   value: 'param', sortable: false  },
            { text: 'fan_#8',   value: 'f8_s', sortable: false  },
            { text: 'fan_#9',   value: 'f9_s', sortable: false  },
            { text: 'fan_#10',  value: 'f10_s', sortable: false  },
            { text: 'fan_#11',  value: 'f11_s', sortable: false  },
            { text: 'fan_#12',  value: 'f12_s', sortable: false  },
            { text: 'fan_#13',  value: 'f13_s', sortable: false  },
            { text: 'fan_#14',  value: 'f14_s', sortable: false  },
            
        ],

        data_fan_right : [
            { param : 'SPEED',  f8_s : 0,  f9_s : 0,  f10_s : 0,  f11_s : 0,  f12_s : 0,  f13_s : 0,  f14_s : 0 }
        ],


        headers_pump:[
            //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
            { text: 'param',   value: 'param', sortable: false  },
            { text: 'pump_#1',   value: 'p1_s', sortable: false  },
            { text: 'pump_#2',   value: 'p2_s', sortable: false  }
           
        ],

        data_pump : [
            { param : 'FREQ',  p1_s : 0,  p2_s : 0 }
        ],

        headers_a_param:[
            //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
            { text: 'NAME',    value: 'name', sortable: false  },
            { text: 'VALUE',   value: 'value', sortable: false  },
            { text: 'NEW_VALUE',   value: 'new_value', sortable: false  }
            
           
        ],

        data_a_param : [
            // { name : 'FAN_TIME_STEP_INTERVAL',      value :  0   , new_value :  0 },
            // { name : 'PUMP_TIME_STEP_INTERVAL',     value :  0   , new_value :  0 },
            // { name : 'FAN_SPEED_MAX',               value :  0   , new_value :  0 },
            // { name : 'FAN_SPEED_MIN',               value :  0   , new_value :  0 },
            // { name : 'K_FAN_STEP_INC',              value :  0   , new_value :  0 },
            // { name : 'K_FAN_STEP_DEC',              value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_FAN_INC_ENABLE',      value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_FAN_INC_DISABLE',     value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_FAN_DEC_DISABLE',     value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_FAN_DEC_ENABLE',      value :  0   , new_value :  0 },
            // { name : 'PUMP_FREQ_MAX',               value :  0   , new_value :  0 },
            // { name : 'PUMP_FREQ_MIN',               value :  0   , new_value :  0 },
            // { name : 'K_PUMP_STEP_INC',             value :  0   , new_value :  0 },
            // { name : 'K_PUMP_STEP_DEC',             value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_PUMP_INC_ENABLE',     value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_PUMP_INC_DISABLE',    value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_PUMP_DEC_DISABLE',    value :  0   , new_value :  0 },
            // { name : 'T_LEVEL_PUMP_DEC_ENABLE',     value :  0   , new_value :  0 }

            { name : 'FAN_SPEED_MAX',      value :  0  , new_value : 0 },
            { name : 'FAN_SPEED_MIN',      value :  0  , new_value : 0 },
            { name : 'FAN_T_MAX',          value :  0  , new_value : 0 },
            { name : 'FAN_T_MIN',          value :  0  , new_value : 0 },

            { name : 'PUMP_FREQ_MAX',      value :  0  , new_value : 0 },
            { name : 'PUMP_FREQ_MIN',      value :  0  , new_value : 0 },
            { name : 'PUMP_T_MAX',         value :  0  , new_value : 0 },
            { name : 'PUMP_T_MIN',         value :  0  , new_value : 0 },

            { name : 'FAN_KP',             value :  0.0  , new_value : 0 },
            { name : 'FAN_KI',             value :  0.0  , new_value : 0 },
            { name : 'FAN_KD',             value :  0.0  , new_value : 0 }

        ],        

        // headers_radiator_tm:[
        //     //{ text: '#1_level',      value: 'n', align: 'start', sortable: false },
        //     { text: 'radiator_#1_in',   value: 'radiator1_t_in', sortable: false  },
        //     { text: 'radiator_#1_out',  value: 'radiator1_t_out', sortable: false  },
        //     { text: 'radiator_#2_in',   value: 'radiator2_t_in', sortable: false  },
        //     { text: 'radiator_#2_out',  value: 'radiator2_t_out', sortable: false  },
            
        // ],

        data_radiator_tm : [
            { radiator1_t_in : 0, radiator1_t_out : 0, radiator2_t_in : 0, radiator2_t_out : 0 },
        ],        

        ar_data_tank_level  : [],
        ar_data_tank_tm     : [],
        ar_data_radiator_tm : [],
        ar_data_fan_left    : [],
        ar_data_fan_right   : [],
        ar_data_pump        : [],

        ar_curr_fan_speed   : [],
        ar_curr_pump_1_freq : [],
        ar_curr_pump_2_freq : [],
        ar_curr_auto_mode   : [],
        ar_new_fan_speed    : [],
        ar_new_pump_1_freq  : [],
        ar_new_pump_2_freq  : [],
        ar_new_pin          : [],
        ar_new_auto_mode    : [],

        ar_auto_param       : [],

        ar_is_first_cycle   : [],

        tickCounter         : 5,

        reqCntOk            : 0,
        reqCntError         : 0,

        currentRow          : 1,

        local_enable        : 1,


        curr_fan_speed      : 0, 
        curr_pump_1_freq    : 0, 
        curr_pump_2_freq    : 0, 
        curr_auto_mode      : 0, 

        curr_pin            : 0,

        new_fan_speed       : 0, 
        new_pump_1_freq     : 0, 
        new_pump_2_freq     : 0, 
        new_auto_mode       : 0, 
        new_pin             : 0,

        message_send        : "no message",
        message_send_cnt    : 0

    
    }),    

    beforeUpdate : function(){
            //console.log( 'beforeUpdate  countCountainer=' + this.countCountainer + ' currentRow=' + this.currentRow );
    },

    created () {
        //this.data_list = this.container_list2;

        if( this.poll_enable==1 )
         {
            
            for( let ii=0; ii<this.countCountainer; ii++ ){
                this.getFile(ii);
            }
        }

        setInterval(
            function() {
                this.fastestBus();
            }.bind(this), 5000
        );       

        
        // let item = [];
        // for( let ii=0; ii<5; ii++ ) 
        // {
        //     item.push(0);
        // }

        // this.ar_curr_fan_speed = item;
        // this.ar_curr_pump_1_freq = item;
        // this.ar_curr_pump_2_freq = item;
        // this.ar_curr_auto_mode = item;
        // this.ar_new_pin = item;

        for( let ii=0; ii<5; ii++ ) 
        {
            this.ar_curr_fan_speed.push(ii);
            this.ar_curr_pump_1_freq.push(0);
            this.ar_curr_pump_2_freq.push(0);
            this.ar_curr_auto_mode.push(0);

            this.ar_is_first_cycle.push(1);

            this.ar_auto_param.push(
                [
                    // { name : 'FAN_TIME_STEP_INTERVAL',      value :  0  , new_value : 0 },
                    // { name : 'PUMP_TIME_STEP_INTERVAL',     value :  0  , new_value : 0 },
                    // { name : 'FAN_SPEED_MAX',               value :  0  , new_value : 0 },
                    // { name : 'FAN_SPEED_MIN',               value :  0  , new_value : 0 },
                    // { name : 'K_FAN_STEP_INC',              value :  0  , new_value : 0 },
                    // { name : 'K_FAN_STEP_DEC',              value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_FAN_INC_ENABLE',      value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_FAN_INC_DISABLE',     value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_FAN_DEC_DISABLE',     value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_FAN_DEC_ENABLE',      value :  0  , new_value : 0 },
                    // { name : 'PUMP_FREQ_MAX',               value :  0  , new_value : 0 },
                    // { name : 'PUMP_FREQ_MIN',               value :  0  , new_value : 0 },
                    // { name : 'K_PUMP_STEP_INC',             value :  0  , new_value : 0 },
                    // { name : 'K_PUMP_STEP_DEC',             value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_PUMP_INC_ENABLE',     value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_PUMP_INC_DISABLE',    value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_PUMP_DEC_DISABLE',    value :  0  , new_value : 0 },
                    // { name : 'T_LEVEL_PUMP_DEC_ENABLE',     value :  0  , new_value : 0 }

                    { name : 'FAN_SPEED_MAX',      value :  0  , new_value : 0 },
                    { name : 'FAN_SPEED_MIN',      value :  0  , new_value : 0 },
                    { name : 'FAN_T_MAX',          value :  0  , new_value : 0 },
                    { name : 'FAN_T_MIN',          value :  0  , new_value : 0 },

                    { name : 'PUMP_FREQ_MAX',      value :  0  , new_value : 0 },
                    { name : 'PUMP_FREQ_MIN',      value :  0  , new_value : 0 },
                    { name : 'PUMP_T_MAX',         value :  0  , new_value : 0 },
                    { name : 'PUMP_T_MIN',         value :  0  , new_value : 0 },

                    { name : 'FAN_KP',             value :  0.0  , new_value : 0 },
                    { name : 'FAN_KI',             value :  0.0  , new_value : 0 },
                    { name : 'FAN_KD',             value :  0.0  , new_value : 0 }
                    

                ]
            );
                        
        }


    },

    computed: {   

        container_list2 : function()
        {
            //let json = JSON.stringify(this.container_list.toString);
            //console.log( "container_list2:" + this.container_list.container_list  );
            let ret=[];
            
            for( let ii=0; ii<this.container_list.length; ii++ ) 
            {
                let item = { "n" : ii+1, "sn" : this.container_list[ii], "type" : "ASI-20HQ", "state" : "unknow", "hb" : 0  };
                ret.push( item );
            }
            //return this.container_list.container_list;



            return ret;
        },

        countCountainer : function() 
        { 
            return this.container_list2.length;
        },

        urlBase : function () { return process.env.VUE_APP_BASE_URI }
      
    },    

    methods : {

        onSetNewParam : function()
        {
            let n = this.currentRow-1;
            this.ar_new_fan_speed[n]    = this.new_fan_speed;
            this.ar_new_pump_1_freq[n]  = this.new_pump_1_freq;
            this.ar_new_pump_2_freq[n]  = this.new_pump_2_freq;
            this.ar_new_auto_mode[n]    = this.new_auto_mode;
            

            this.ar_new_pin[n] = this.new_pin;

            console.log( "onSetNewParam  new_pin=" + this.new_pin + " currentRow=" + this.currentRow);

            this.message_send_cnt++;
            this.message_send = "send request " + this.message_send_cnt;


            let req = new XMLHttpRequest();
            req.onload = function() {
                if (req.status == 200) {

                        this.reqCntOk++;

                        let json_o = JSON.parse( req.responseText );

                        let is_approved = json_o.is_approved;

                        if( 1!=is_approved )
                        {
                            this.local_enable=0;
                            this.$alert( "Server error. You need logout and new login.");
                            return;
                        }

                } else {
                        //myCallback("Error: " + req.status);
                        this.reqCntError++;
                }
            }.bind(this)


            this.ar_auto_param[n][0].new_value =  this.data_a_param[0].new_value; 
            this.ar_auto_param[n][1].new_value =  this.data_a_param[1].new_value; 
            this.ar_auto_param[n][2].new_value =  this.data_a_param[2].new_value; 
            this.ar_auto_param[n][3].new_value =  this.data_a_param[3].new_value; 
            this.ar_auto_param[n][4].new_value =  this.data_a_param[4].new_value; 
            this.ar_auto_param[n][5].new_value =  this.data_a_param[5].new_value; 
            this.ar_auto_param[n][6].new_value =  this.data_a_param[6].new_value; 
            this.ar_auto_param[n][7].new_value =  this.data_a_param[7].new_value; 
            this.ar_auto_param[n][8].new_value =  this.data_a_param[8].new_value; 
            this.ar_auto_param[n][9].new_value =  this.data_a_param[9].new_value; 
            this.ar_auto_param[n][10].new_value = this.data_a_param[10].new_value; 
            // this.ar_auto_param[n][11].new_value = this.data_a_param[11].new_value; 
            // this.ar_auto_param[n][12].new_value = this.data_a_param[12].new_value; 
            // this.ar_auto_param[n][13].new_value = this.data_a_param[13].new_value; 
            // this.ar_auto_param[n][14].new_value = this.data_a_param[14].new_value; 
            // this.ar_auto_param[n][15].new_value = this.data_a_param[15].new_value; 
            // this.ar_auto_param[n][16].new_value = this.data_a_param[16].new_value; 
            // this.ar_auto_param[n][17].new_value = this.data_a_param[17].new_value;

            let json = JSON.stringify({
                token : this.user_token,
                cmd   : "set_container_param",
                container_id : this.container_list[n],
                fan_speed       :  this.new_fan_speed,
                pump_1_freq     :   this.new_pump_1_freq,
                pump_2_freq     :   this.new_pump_2_freq,
                pin             :   this.new_pin,
                auto_mode       :   this.new_auto_mode
                ,

                FAN_SPEED_MAX   : this.ar_auto_param[n][0].new_value,
                FAN_SPEED_MIN   : this.ar_auto_param[n][1].new_value,
                FAN_T_MAX       : this.ar_auto_param[n][2].new_value,
                FAN_T_MIN       : this.ar_auto_param[n][3].new_value,
                PUMP_FREQ_MAX   : this.ar_auto_param[n][4].new_value,
                PUMP_FREQ_MIN   : this.ar_auto_param[n][5].new_value,
                PUMP_T_MAX      : this.ar_auto_param[n][6].new_value,
                PUMP_T_MIN      : this.ar_auto_param[n][7].new_value,
                FAN_KP          : this.ar_auto_param[n][8].new_value,
                FAN_KI          : this.ar_auto_param[n][9].new_value,
                FAN_KD          : this.ar_auto_param[n][10].new_value

                // FAN_TIME_STEP_INTERVAL      :  this.ar_auto_param[n][0].new_value, 
                // PUMP_TIME_STEP_INTERVAL     :  this.ar_auto_param[n][1].new_value, 
                // FAN_SPEED_MAX               :  this.ar_auto_param[n][2].new_value, 
                // FAN_SPEED_MIN               :  this.ar_auto_param[n][3].new_value, 
                // K_FAN_STEP_INC              :  this.ar_auto_param[n][4].new_value, 
                // K_FAN_STEP_DEC              :  this.ar_auto_param[n][5].new_value, 
                // T_LEVEL_FAN_INC_ENABLE      :  this.ar_auto_param[n][6].new_value, 
                // T_LEVEL_FAN_INC_DISABLE     :  this.ar_auto_param[n][7].new_value, 
                // T_LEVEL_FAN_DEC_DISABLE     :  this.ar_auto_param[n][8].new_value, 
                // T_LEVEL_FAN_DEC_ENABLE      :  this.ar_auto_param[n][9].new_value, 
                // PUMP_FREQ_MAX               :  this.ar_auto_param[n][10].new_value, 
                // PUMP_FREQ_MIN               :  this.ar_auto_param[n][11].new_value, 
                // K_PUMP_STEP_INC             :  this.ar_auto_param[n][12].new_value, 
                // K_PUMP_STEP_DEC             :  this.ar_auto_param[n][13].new_value, 
                // T_LEVEL_PUMP_INC_ENABLE     :  this.ar_auto_param[n][14].new_value, 
                // T_LEVEL_PUMP_INC_DISABLE    :  this.ar_auto_param[n][15].new_value, 
                // T_LEVEL_PUMP_DEC_DISABLE    :  this.ar_auto_param[n][16].new_value, 
                // T_LEVEL_PUMP_DEC_ENABLE     :  this.ar_auto_param[n][17].new_value                
            });

            var url = "req=" + json.toString();
            req.withCredentials = true;
            //req.open("POST", 'http://localhost/f/data', true)
            req.open("POST", this.urlBase+'/f/data', true)

            console.log( "send: ",this.urlBase,"/f/data");

            //req.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            //req.setRequestHeader('Access-Control-Allow-Origin', "http://localhost/f/data" );
            req.send(url);
            console.log( url );

        },

        onSetNewPin : function( event )
        {
            console.log( "onSetNewPin" + event.text );
        },

        onClickRow : function( event )
        {
            this.currentRow = event.n;
           // this.data_container = this.ar_data[event.n-1];
            //row.select(true);

            let n = event.n-1;

            this.new_pin         = this.ar_new_pin[n];

//             this.new_fan_speed      = this.ar_new_fan_speed[n];
//             this.new_pump_1_freq    = this.ar_new_pump_1_freq[n];
//             this.new_pump_2_freq    = this.ar_new_pump_2_freq[n];
// //            this.new_pin         =234;//   = this.ar_new_pin[n];
//             this.new_auto_mode     = this.ar_new_auto_mode[n];

            console.log( "new_pin=" + this.new_pin );

            this.data_tank_level  = this.ar_data_tank_level[n];
            this.data_tank_tm     = this.ar_data_tank_tm[n];
            this.data_radiator_tm = this.ar_data_radiator_tm[n];
            this.data_fan_left    = this.ar_data_fan_left[n];
            this.data_fan_right   = this.ar_data_fan_right[n];
            this.data_pump        = this.ar_data_pump[n];

            this.curr_fan_speed     = this.ar_curr_fan_speed[n];
            this.curr_pump_1_freq   = this.ar_curr_pump_1_freq[n];
            this.curr_pump_2_freq   = this.ar_curr_pump_2_freq[n];
            //this.curr_pin           = this.ar_curr_pin[n];
            this.curr_auto_mode     = this.ar_curr_auto_mode[n];

            this.new_fan_speed      = this.ar_new_fan_speed[n];
            this.new_pump_1_freq    = this.ar_new_pump_1_freq[n];
            this.new_pump_2_freq    = this.ar_new_pump_2_freq[n];
//            this.new_pin         =234;//   = this.ar_new_pin[n];
            this.new_auto_mode     = this.ar_new_auto_mode[n];

            this.data_a_param       = this.ar_auto_param[n];

            for( let ii=0; ii<11; ii++ ) 
            {
                this.data_a_param[ii].new_value = this.data_a_param[ii].value;
            }
        },

 

    getFile : function ( n ) {
        let req = new XMLHttpRequest();
        req.onload = function() {
            if (req.status == 200) {
                    //myCallback(this.responseText);
                    //this.reqResponseText = req.responseText;
                    this.reqCntOk++;
                    //console.log( "onLoad: " + req.responseText );

                    //var str = '{ "tanks" : 58 }';
                    //var json_p = JSON.stringify( req.responseText );
                    //let json_p = JSON.stringify( str );
                    let json_o = JSON.parse( req.responseText );

                    let is_approved = json_o.is_approved;

                    if( 1!=is_approved )
                    {
                        this.local_enable=0;
                        this.$alert( "Server error. You need logout and new login.");
                        return;
                    }

                    this.container_list2[n].hb = json_o.hb;
                    this.container_list2[n].state = json_o.connection;
                    let tanks_level = json_o.tanks_level;
                    let level0;
                    let level1;
                    let ar_level= [];
                    let ar_tank_tm = [];
                    let ar_radiator_tm = [];
                    let ar_fan_left = [];
                    let ar_fan_right = [];
                    let ar_pump = [];
                    let val;
                    let vals = [];
                    let item;
                    for( let ii=0; ii<8; ii++ )
                    {
                        level0 = tanks_level[ii].t_level_0;
                        level1 = tanks_level[ii].t_level_1;
                        if( level0==1 )
                            val = 'CRITICAL';
                        else if( level0==0 && level1==1 ) 
                            val = 'LOW';
                        else
                            val = 'FULL';

                        vals.push(val);
                    }
                    item = { t1_level : vals[0], t2_level : vals[1], t3_level : vals[2], t4_level : vals[3],
                             t5_level : vals[4], t6_level : vals[5], t7_level : vals[6], t8_level : vals[7] };
                    ar_level.push(item);

                    let tank_temparature = json_o.tanks_temperature;


                    // item = {    param : "T_INPUT", 
                    //             t12_t : tank_temparature[0].t_in, 
                    //             t34_t : tank_temparature[1].t_in, 
                    //             t56_t : tank_temparature[2].t_in, 
                    //             t78_t : tank_temparature[3].t_in 
                    //         };

                    item = {    param : "T_INPUT", 
                        t1_t : tank_temparature[0].t_in, 
                        t2_t : tank_temparature[1].t_in, 
                        t3_t : tank_temparature[2].t_in, 
                        t4_t : tank_temparature[3].t_in, 
                        t5_t : tank_temparature[4].t_in, 
                        t6_t : tank_temparature[5].t_in, 
                        t7_t : tank_temparature[6].t_in, 
                        t8_t : tank_temparature[7].t_in 
                    };

                    ar_tank_tm.push( item );

                    // item = {    param : "T_OUTPUT", 
                    //             t12_t : tank_temparature[0].t_out, 
                    //             t34_t : tank_temparature[1].t_out, 
                    //             t56_t : tank_temparature[2].t_out, 
                    //             t78_t : tank_temparature[3].t_out 
                    //         };

                    item = {    param : "T_OUTPUT", 
                                t1_t : tank_temparature[0].t_out, 
                                t2_t : tank_temparature[1].t_out, 
                                t3_t : tank_temparature[2].t_out, 
                                t4_t : tank_temparature[3].t_out,
                                t5_t : tank_temparature[4].t_out, 
                                t6_t : tank_temparature[5].t_out, 
                                t7_t : tank_temparature[6].t_out, 
                                t8_t : tank_temparature[7].t_out 
                            };

                    ar_tank_tm.push( item );

                    item = {    param : "LEVEL", 
                                t1_t : "FULL", 
                                t2_t : "FULL", 
                                t3_t : "FULL", 
                                t4_t : "FULL",
                                t5_t : "FULL", 
                                t6_t : "FULL", 
                                t7_t : "FULL", 
                                t8_t : "FULL" 
                            };

                    ar_tank_tm.push( item );


                    let fan_speed = json_o.fan_speed

                    //console.log( "fan_speed[0]: "+ fan_speed[0].speed);

                    item = {    param    : "fan 1-7",
                                f1_s     : fan_speed[0].speed, 
                                f2_s     : fan_speed[1].speed, 
                                f3_s     : fan_speed[2].speed, 
                                f4_s     : fan_speed[3].speed, 
                                f5_s     : fan_speed[4].speed, 
                                f6_s     : fan_speed[5].speed, 
                                f7_s     : fan_speed[6].speed
                            };

                    ar_fan_left.push( item );

                    item = {    param    : "fan 8-14",
                                f1_s     : fan_speed[7].speed, 
                                f2_s     : fan_speed[8].speed, 
                                f3_s    : fan_speed[9].speed, 
                                f4_s    : fan_speed[10].speed, 
                                f5_s    : fan_speed[11].speed, 
                                f6_s    : fan_speed[12].speed, 
                                f7_s    : fan_speed[13].speed 
                            };

                    //ar_fan_right.push( item );
                    ar_fan_left.push( item );

                    let pump_freq = json_o.pump_freq;
                    // console.log( pump_freq);
                    // console.log( "pump_freq[0]: "+ pump_freq[0].freq);
                    // console.log( "pump_freq[1]: "+ pump_freq[1].freq);

                    item = {    
                                p1_s     : pump_freq[0].freq, 
                                p2_s     : pump_freq[1].freq, 
                            };


                    ar_pump.push( item );

                    this.ar_data_tank_level[n]  = ar_level;
                    this.ar_data_tank_tm[n]     = ar_tank_tm;
                    this.ar_data_radiator_tm[n] = ar_radiator_tm;
                    this.ar_data_fan_left[n]    = ar_fan_left;
                    this.ar_data_fan_right[n]   = ar_fan_right;
                    this.ar_data_pump[n]        = ar_pump;

                    let curr_param = json_o.current_mode;                        
                    //console.log( curr_param);
                    this.ar_curr_fan_speed[n]       = curr_param.fan_speed;
                    this.ar_curr_auto_mode[n]       = curr_param.auto_mode;
                    this.ar_curr_pump_1_freq[n]     = curr_param.pump1_freq;
                    this.ar_curr_pump_2_freq[n]     = curr_param.pump2_freq;

                    let curr_auto_param = json_o.current_auto_param;

                    let xx =                 
                        [
                            // { name : 'FAN_TIME_STEP_INTERVAL',      value :  curr_auto_param.FAN_TIME_STEP_INTERVAL     , new_value :  this.ar_auto_param[n][0].new_value },
                            // { name : 'PUMP_TIME_STEP_INTERVAL',     value :  curr_auto_param.PUMP_TIME_STEP_INTERVAL    , new_value :  this.ar_auto_param[n][1].new_value },
                            // { name : 'FAN_SPEED_MAX',               value :  curr_auto_param.FAN_SPEED_MAX              , new_value :  this.ar_auto_param[n][2].new_value },
                            // { name : 'FAN_SPEED_MIN',               value :  curr_auto_param.FAN_SPEED_MIN              , new_value :  this.ar_auto_param[n][3].new_value },
                            // { name : 'K_FAN_STEP_INC',              value :  curr_auto_param.K_FAN_STEP_INC             , new_value :  this.ar_auto_param[n][4].new_value },
                            // { name : 'K_FAN_STEP_DEC',              value :  curr_auto_param.K_FAN_STEP_DEC             , new_value :  this.ar_auto_param[n][5].new_value },
                            // { name : 'T_LEVEL_FAN_INC_ENABLE',      value :  curr_auto_param.T_LEVEL_FAN_INC_ENABLE     , new_value :  this.ar_auto_param[n][6].new_value },
                            // { name : 'T_LEVEL_FAN_INC_DISABLE',     value :  curr_auto_param.T_LEVEL_FAN_INC_DISABLE    , new_value :  this.ar_auto_param[n][7].new_value },
                            // { name : 'T_LEVEL_FAN_DEC_DISABLE',     value :  curr_auto_param.T_LEVEL_FAN_DEC_DISABLE    , new_value :  this.ar_auto_param[n][8].new_value },
                            // { name : 'T_LEVEL_FAN_DEC_ENABLE',      value :  curr_auto_param.T_LEVEL_FAN_DEC_ENABLE     , new_value :  this.ar_auto_param[n][9].new_value },
                            // { name : 'PUMP_FREQ_MAX',               value :  curr_auto_param.PUMP_FREQ_MAX              , new_value :  this.ar_auto_param[n][10].new_value },
                            // { name : 'PUMP_FREQ_MIN',               value :  curr_auto_param.PUMP_FREQ_MIN              , new_value :  this.ar_auto_param[n][11].new_value },
                            // { name : 'K_PUMP_STEP_INC',             value :  curr_auto_param.K_PUMP_STEP_INC            , new_value :  this.ar_auto_param[n][12].new_value },
                            // { name : 'K_PUMP_STEP_DEC',             value :  curr_auto_param.K_PUMP_STEP_DEC            , new_value :  this.ar_auto_param[n][13].new_value },
                            // { name : 'T_LEVEL_PUMP_INC_ENABLE',     value :  curr_auto_param.T_LEVEL_PUMP_INC_ENABLE    , new_value :  this.ar_auto_param[n][14].new_value },
                            // { name : 'T_LEVEL_PUMP_INC_DISABLE',    value :  curr_auto_param.T_LEVEL_PUMP_INC_DISABLE   , new_value :  this.ar_auto_param[n][15].new_value },
                            // { name : 'T_LEVEL_PUMP_DEC_DISABLE',    value :  curr_auto_param.T_LEVEL_PUMP_DEC_DISABLE   , new_value :  this.ar_auto_param[n][16].new_value },
                            // { name : 'T_LEVEL_PUMP_DEC_ENABLE',     value :  curr_auto_param.T_LEVEL_PUMP_DEC_ENABLE    , new_value :  this.ar_auto_param[n][17].new_value }

                            { name : 'FAN_SPEED_MAX',      value :  curr_auto_param.FAN_SPEED_MAX , new_value : this.ar_auto_param[n][0].new_value },
                            { name : 'FAN_SPEED_MIN',      value :  curr_auto_param.FAN_SPEED_MIN , new_value : this.ar_auto_param[n][1].new_value },
                            { name : 'FAN_T_MAX',          value :  curr_auto_param.FAN_T_MAX ,     new_value : this.ar_auto_param[n][2].new_value },
                            { name : 'FAN_T_MIN',          value :  curr_auto_param.FAN_T_MIN ,     new_value : this.ar_auto_param[n][3].new_value },
                            { name : 'PUMP_FREQ_MAX',      value :  curr_auto_param.PUMP_FREQ_MAX , new_value : this.ar_auto_param[n][4].new_value },
                            { name : 'PUMP_FREQ_MIN',      value :  curr_auto_param.PUMP_FREQ_MIN , new_value : this.ar_auto_param[n][5].new_value },
                            { name : 'PUMP_T_MAX',         value :  curr_auto_param.PUMP_T_MAX ,    new_value : this.ar_auto_param[n][6].new_value },
                            { name : 'PUMP_T_MIN',         value :  curr_auto_param.PUMP_T_MIN ,    new_value : this.ar_auto_param[n][7].new_value },
                            { name : 'FAN_KP',             value :  curr_auto_param.FAN_KP ,        new_value : this.ar_auto_param[n][8].new_value },
                            { name : 'FAN_KI',             value :  curr_auto_param.FAN_KI ,        new_value : this.ar_auto_param[n][9].new_value },
                            { name : 'FAN_KD',             value :  curr_auto_param.FAN_KD ,        new_value : this.ar_auto_param[n][10].new_value }

                        ];

                    this.ar_auto_param[n] = xx;

                    if( 1==this.ar_is_first_cycle[n])
                    {

                        this.ar_is_first_cycle[n] = 0;

                        console.log( "set new param: n=" + n + " row=" + this.currentRow + " ar_is_first_cycle[n]=" + this.ar_is_first_cycle[n] )

                        this.ar_new_fan_speed[n]     = this.ar_curr_fan_speed[n];
                        this.ar_new_pump_1_freq[n]   = this.ar_curr_pump_1_freq[n];
                        this.ar_new_pump_2_freq[n]   = this.ar_curr_pump_2_freq[n];
                        this.ar_new_auto_mode[n]     = this.ar_auto_mode[n];
                    
                        if( (n+1)==this.currentRow )
                        {
                            this.new_fan_speed = this.ar_new_fan_speed[n];
                            this.new_pump_1_freq = this.ar_new_pump_1_freq[n];
                            this.new_pump_2_freq = this.ar_new_pump_2_freq[n];
                            this.new_auto_mode = this.ar_new_auto_mode[n];
                        }


                        //this.data_a_param = xx;

                        // this.ar_auto_param[n][0].new_value =   curr_auto_param.FAN_TIME_STEP_INTERVAL  ;
                        // this.ar_auto_param[n][1].new_value =   curr_auto_param.PUMP_TIME_STEP_INTERVAL ;
                        // this.ar_auto_param[n][2].new_value =   curr_auto_param.FAN_SPEED_MAX           ;
                        // this.ar_auto_param[n][3].new_value =   curr_auto_param.FAN_SPEED_MIN           ;
                        // this.ar_auto_param[n][4].new_value =   curr_auto_param.K_FAN_STEP_INC          ;
                        // this.ar_auto_param[n][5].new_value =   curr_auto_param.K_FAN_STEP_DEC          ;
                        // this.ar_auto_param[n][6].new_value =   curr_auto_param.T_LEVEL_FAN_INC_ENABLE  ;
                        // this.ar_auto_param[n][7].new_value =   curr_auto_param.T_LEVEL_FAN_INC_DISABLE ;
                        // this.ar_auto_param[n][8].new_value =   curr_auto_param.T_LEVEL_FAN_DEC_DISABLE ;
                        // this.ar_auto_param[n][9].new_value =   curr_auto_param.T_LEVEL_FAN_DEC_ENABLE  ;
                        // this.ar_auto_param[n][10].new_value =   curr_auto_param.PUMP_FREQ_MAX           ;
                        // this.ar_auto_param[n][11].new_value =   curr_auto_param.PUMP_FREQ_MIN           ;
                        // this.ar_auto_param[n][12].new_value =   curr_auto_param.K_PUMP_STEP_INC         ;
                        // this.ar_auto_param[n][13].new_value =   curr_auto_param.K_PUMP_STEP_DEC         ;
                        // this.ar_auto_param[n][14].new_value =   curr_auto_param.T_LEVEL_PUMP_INC_ENABLE ;
                        // this.ar_auto_param[n][15].new_value =   curr_auto_param.T_LEVEL_PUMP_INC_DISABLE;
                        // this.ar_auto_param[n][16].new_value =   curr_auto_param.T_LEVEL_PUMP_DEC_DISABLE;
                        // this.ar_auto_param[n][17].new_value =   curr_auto_param.T_LEVEL_PUMP_DEC_ENABLE ;

                        if( (n+1)==this.currentRow )
                        {
                            //this.data_a_param = this.ar_auto_param[n];
                            this.data_a_param[0].value =   curr_auto_param.FAN_SPEED_MAX;
                            this.data_a_param[1].value =   curr_auto_param.FAN_SPEED_MIN ;
                            this.data_a_param[2].value =   curr_auto_param.FAN_T_MAX ;
                            this.data_a_param[3].value =   curr_auto_param.FAN_T_MIN ;
                            this.data_a_param[4].value =   curr_auto_param.PUMP_FREQ_MAX ;
                            this.data_a_param[5].value =   curr_auto_param.PUMP_FREQ_MIN ;
                            this.data_a_param[6].value =   curr_auto_param.PUMP_T_MAX ;
                            this.data_a_param[7].value =   curr_auto_param.PUMP_T_MIN ;
                            this.data_a_param[8].value =   curr_auto_param.FAN_KP ;
                            this.data_a_param[9].value =   curr_auto_param.FAN_KI ;
                            this.data_a_param[10].value =  curr_auto_param.FAN_KD ;

                        }
                    }                    

                    if( (n+1)==this.currentRow) 
                    {
                        this.data_tank_level  = ar_level;
                        this.data_tank_tm     = ar_tank_tm;
                        this.data_radiator_tm = ar_radiator_tm;
                        this.data_fan_left    = ar_fan_left;
                        this.data_fan_right   = ar_fan_right;
                        this.data_pump        = ar_pump;


                        this.curr_fan_speed     = this.ar_curr_fan_speed[n];
                        this.curr_pump_1_freq   = this.ar_curr_pump_1_freq[n];
                        this.curr_pump_2_freq   = this.ar_curr_pump_2_freq[n];
                        this.curr_auto_mode     = this.ar_auto_mode[n];

                        this.data_a_param[0].value =   curr_auto_param.FAN_SPEED_MAX;
                        this.data_a_param[1].value =   curr_auto_param.FAN_SPEED_MIN ;
                        this.data_a_param[2].value =   curr_auto_param.FAN_T_MAX ;
                        this.data_a_param[3].value =   curr_auto_param.FAN_T_MIN ;
                        this.data_a_param[4].value =   curr_auto_param.PUMP_FREQ_MAX ;
                        this.data_a_param[5].value =   curr_auto_param.PUMP_FREQ_MIN ;
                        this.data_a_param[6].value =   curr_auto_param.PUMP_T_MAX ;
                        this.data_a_param[7].value =   curr_auto_param.PUMP_T_MIN ;
                        this.data_a_param[8].value =   curr_auto_param.FAN_KP ;
                        this.data_a_param[9].value =   curr_auto_param.FAN_KI ;
                        this.data_a_param[10].value =  curr_auto_param.FAN_KD ;


                        // this.data_a_param[0].value =   curr_auto_param.FAN_TIME_STEP_INTERVAL  ;
                        // this.data_a_param[1].value =   curr_auto_param.PUMP_TIME_STEP_INTERVAL ;
                        // this.data_a_param[2].value =   curr_auto_param.FAN_SPEED_MAX           ;
                        // this.data_a_param[3].value =   curr_auto_param.FAN_SPEED_MIN           ;
                        // this.data_a_param[4].value =   curr_auto_param.K_FAN_STEP_INC          ;
                        // this.data_a_param[5].value =   curr_auto_param.K_FAN_STEP_DEC          ;
                        // this.data_a_param[6].value =   curr_auto_param.T_LEVEL_FAN_INC_ENABLE  ;
                        // this.data_a_param[7].value =   curr_auto_param.T_LEVEL_FAN_INC_DISABLE ;
                        // this.data_a_param[8].value =   curr_auto_param.T_LEVEL_FAN_DEC_DISABLE ;
                        // this.data_a_param[9].value =   curr_auto_param.T_LEVEL_FAN_DEC_ENABLE  ;
                        // this.data_a_param[10].value =   curr_auto_param.PUMP_FREQ_MAX           ;
                        // this.data_a_param[11].value =   curr_auto_param.PUMP_FREQ_MIN           ;
                        // this.data_a_param[12].value =   curr_auto_param.K_PUMP_STEP_INC         ;
                        // this.data_a_param[13].value =   curr_auto_param.K_PUMP_STEP_DEC         ;
                        // this.data_a_param[14].value =   curr_auto_param.T_LEVEL_PUMP_INC_ENABLE ;
                        // this.data_a_param[15].value =   curr_auto_param.T_LEVEL_PUMP_INC_DISABLE;
                        // this.data_a_param[16].value =   curr_auto_param.T_LEVEL_PUMP_DEC_DISABLE;
                        // this.data_a_param[17].value =   curr_auto_param.T_LEVEL_PUMP_DEC_ENABLE ;                        
                    }
                    //console.log( "onLoad: " + json_o );
                    //var tanks = json_o.tanks;
                    //console.log( "tanks: " + tanks );
                    // this.containerName = json_o.container_name;
                    // this.containerSN = json_o.container_sn;
                    // for( var ii=0; ii<8; ii++ )
                    // {
                    //     //var item = json_o.tanks[ii];
                    //     this.tankData.items[ii].t_num   = json_o.tanks[ii].t_num;
                    //     this.tankData.items[ii].t_tm    = json_o.tanks[ii].t_in;
                    //     // this.tankData.items[ii].t_in    = json_o.tanks[ii].t_in;
                    //     // this.tankData.items[ii].t_out   = json_o.tanks[ii].t_out;
                    //     // this.tankData.items[ii].t_level = json_o.tanks[ii].t_level;
                    //     // this.tankData.items[ii].t_pump  = json_o.tanks[ii].t_pump;
                    // 
                
            } else {
                    //myCallback("Error: " + req.status);
                    this.reqCntError++;
            }
        }.bind(this)

        let json = JSON.stringify({
            token : this.user_token,
            cmd   : "get_container_data",
            container_id : this.container_list[n]
        });

        var url = "req=" + json.toString();
        req.withCredentials = true;
        //req.open("POST", 'http://localhost/f/data', true)
        req.open("POST", this.urlBase+'/f/data', true)

        //console.log( "send: ",this.urlBase,"/f/data");

        //req.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        //req.setRequestHeader('Access-Control-Allow-Origin', "http://localhost:8080/f/data" );
        req.send(url);

    },


    fastestBus : function(){


         //this.tankData.items[2].t_out = 55;
            //this.tankData.tick = 55;

         this.tickCounter++;
         //this.tankDataInt.tick = this.tickCounter;
         //this.tankData.tick = this.tickCounter;
         //this.tankData = this.tankDataInt;
         //this.tankData = this.tickCounter;
         //console.log( "fastestBus " + this.tankData.tick );

         if( this.poll_enable==1 && this.local_enable==1)
         {
            
            for( let ii=0; ii<this.countCountainer; ii++ ){
                this.getFile(ii);
            }
        }
         
    }
    }

  }


//getFile(myDisplayer);

  </script>
  