<template>
    <div class="login-container">
    
        <div class="login-box">
            <form>
                <div class="form-group">
                <label for="username">Username:</label>
                <input type="text" id="username" v-model="username" class="input-field">
                </div>
                <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" v-model="password" class="input-field">
                </div>
                <br>
                <button type="submit" @click.prevent="login" class="login-button">Login</button>
                <br>
                For demo access please enter username: "demo" and password: "123"
                <p>{{ result }}</p>
            </form>

            <!-- <p align="center">answer: {{ answer }}</p>  -->
        </div>
        
    
    </div>
  </template>
  
  <style>
   .welcome-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .login-box {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
  .input-field {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-left: 10px;
    width: 200px;
    box-sizing: border-box;
  }
  label {
    margin-right: 10px;
  }
  .login-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .login-button:hover {
    background-color: #3e8e41;
  }
  </style>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        answer: '',
        result: ''
      }
    },

    computed: {   

        urlBase : function () { return process.env.VUE_APP_BASE_URI },    
    },
    methods: {
      login() {
        // Perform login logic here
        console.log('Logging in with username:', this.username, 'and password:', this.password);
        //this.$emit('login-approve', this.username, "token" );
        this.getFile();
      },

    getFile : function () {
        let req = new XMLHttpRequest();
        req.onload = function() {
            if (req.status == 200) {
                    //this.reqCntOk++;
                    let json_o = JSON.parse( req.responseText );
                    this.answer = json_o;

                    
                    let is_approved  = json_o.is_approved;
                    let user_token  = json_o.user_token;

                    console.log( json_o );
                    console.log( "is_approved: " + is_approved );
                    console.log( "user_token: " + user_token );

                    if( is_approved==1 ){
                        this.result = "Login is correct";    
                        this.$emit('login-approve', this.username, user_token );
                    } else{
                        this.result = "Login or password is incorrect";
                    }

            } else {
                    //myCallback("Error: " + req.status);
                    //this.reqCntError++;
                    this.answer = 'no answer';
            }
        }.bind(this)
    // req.open('GET', "mycar.html");
    // req.send();

        let json = JSON.stringify({
            login : this.username,
            password: this.password
        });

        var url = "login=" + json.toString();
        req.withCredentials = true;
        req.open("POST", this.urlBase+'/f/login', true)

        //req.open("POST", 'https://localhost:80/f/data', true)

        req.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        req.send(url);

    }
    },

  }
  </script>
  